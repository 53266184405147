const envSettings = window._env_;

export default class Config {

  static errorCodeLinkMap = null

  static get_nast_v2_api_url(){
    const nast_v2_api_url = envSettings.NAST_V2_SERVICE_URL
    if(nast_v2_api_url.startsWith("http")){
      return nast_v2_api_url
    }else{
      return window.location.origin + nast_v2_api_url
    }
  }

  static get_nast_docs_url(){
    const nast_docs_url = envSettings.NAST_DOCS_SERVICE_URL
    if(nast_docs_url.startsWith("http")){
      return nast_docs_url
    }else{
      return window.location.origin + nast_docs_url
    }
  }

  static setErrorCodeLinkMap(newErrorCodeLinkMap){
    this.errorCodeLinkMap = newErrorCodeLinkMap
  }

  static getErrorCodeLinkMap(){
      return this.errorCodeLinkMap
  }
}
import Config from './AppConfig'

export async function call_resolve(domain){
    if(domain.trim() === ""){
        return {success: false, data: {severity: 'error', message: 'domain_input_empty_error_text'}}
    }

    let response = null
    try {
        response = await fetch(Config.get_nast_v2_api_url() + 'v2/resolve/'+ domain)
    } catch(error) {
        return {success: false, data: {severity: 'error', message: 'nast_unreachable_error_text'}}
    }

    if(response.status === 404){
        return {success: false, data: {severity: 'error', message: 'domain_not_found_error_text'}}
    }else if(response.status === 400){
        return {success: false, data: {severity: 'error', message: 'api_error_400'}}
    }else if(!response.ok){
        return {success: false, data: {severity: 'error', message: 'nast_unexpected_error_text'}}
    }

    let data = null
    try{
        data = await response.json()
    } catch(error){
        return {success: false, data: {severity: 'error', message: 'error_while_encoding_response'}}
    }

    return {
        success: true,
        data: {
            domain: domain,
            nameserver: Object.keys(data.nameserver).map(ns => ({
                server: ns,
                ips: data.nameserver[ns].join(' ')
            })),
            dnskeys: "dnskey" in data ? data.dnskey.map(dnskey => ({
                flags: dnskey.flags.toString(),
                algorithm: dnskey.algorithm,
                dnskey: dnskey.key
            })) : [],
        }
    }

}

export function getIPListOfString(ipsString){
    let ips = []

    let i = 0
    while(i < ipsString.length){
        let j = i
        while(j < ipsString.length){
            if(ipsString[j] === " " || ipsString[j] === "," || ipsString[j] === "\n"){
                break
            }
            j++
        }
        if(i !== j){
            ips.push(ipsString.substring(i, j))
            i = j
        }
        i++
    }
    return ips
}

export function build_url(baseurl, resourceIdentifier, domain, nameserver, dnskeys, debugMode) {
    const url = new URL(resourceIdentifier + domain, baseurl)

    for(let i = 0; i < nameserver.length; i++) {
        let key = "ns"
        key += (i < 2) ? i + 1 : "X"

        let value = nameserver[i]['server']
        value += (nameserver[i]['ips'].trim() !== "") ? "," + getIPListOfString(nameserver[i]['ips']).join(',') : ""

        url.searchParams.append(key, value)
    }

    for(let i = 0; i < dnskeys.length; i++) {
        let key = "dnskey"
        let value = dnskeys[i].flags + " 3 " + dnskeys[i].algorithm + " " + dnskeys[i].dnskey
        url.searchParams.append(key, value)
    }

    if(debugMode){
        url.searchParams.append("debug", "True")
    }

    return url
}

export async function call_check(domain, nameserver, dnskeys, debugMode){
    const url = build_url(Config.get_nast_v2_api_url(), "v2/check/", domain, nameserver, dnskeys, debugMode)

    let response = null
    try {
        response = await fetch(url)
    } catch(error) {
        return {success: false, data: {severity: 'error', message: 'nast_unreachable_error_text'}}
    }

    if(response.status === 400){
        return {success: false, data: {severity: 'error', message: 'api_error_400'}}
    } else if(!response.ok){
        return {success: false, data: {severity: 'error', message: 'nast_unexpected_error_text'}}
    }

    let data = null
    try{
        data = await response.json()
    } catch(error){
        return {success: false, data: {severity: 'error', message: 'error_while_encoding_response'}}
    }

    return {success: true, data: data}
}

export async function get_errorcode_docslink_map(){
    const url = Config.get_nast_docs_url() + 'static/code-link-map.json'

    let response = null
    try {
        response = await fetch(url)
    } catch(error) {
        return {success: false, data: {severity: 'error', message: 'nast_docs_unreachable_error_text'}}
    }

    if(response.status === 400){
        return {success: false, data: {severity: 'error', message: 'api_error_400'}}
    } else if(!response.ok){
        return {success: false, data: {severity: 'error', message: 'nast_unexpected_error_text'}}
    }

    let data = null
    try{
        data = await response.json()
    } catch(error){
        return {success: false, data: {severity: 'error', message: 'error_while_encoding_response'}}
    }

    return {success: true, data: data}
}

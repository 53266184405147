import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import AppRouter from './AppRouter';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Icon from "@material-ui/core/Icon";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import LanguageSelect from "./languageSelect";
import { useTranslation} from "react-i18next";
import Config from './AppConfig'
import Divider from '@material-ui/core/Divider';
import i18n from "i18next";
import {get_errorcode_docslink_map} from "./Api";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#007A9D"
    },
    secondary: {
      action: {
        hover: "#ff8003",
      },
      main: "#ff9a00",
    },
    action: {
      hover: "#ff8003",
    }
  },
});

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    height: "80px",
    boxShadow: "0 2px 3px 0 rgb(0 0 0 / 16%)",
    backgroundColor: "#fefefe",
  },
  appBarInner: {
    maxWidth: "73.75rem",
    paddingRight: "1.71875rem",
    paddingLeft: "1.71875rem",
    marginLeft: "auto",
    marginRight: "auto",
    height: "100%",
    alignItems: "center",
  },
  appBarItem:{
    height: "100%"
  },
  centerText: {
    textAlign: "center"
  },
  rightText: {
    textAlign: "right"
  },
  denicLogo: {
    width: "7rem"
  },
  introText: {
    padding: theme.spacing(2),
  },
  link: {
    color: "#000000",
    "&:hover": {
      color: theme.palette.secondary.main,
      textDecoration: "none"
    }
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  linkContainer:{
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(3),
    textAlign: "center"
  },
  linkContainerInner: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    textAlign: "start"
  },
  footer: {
    marginTop: theme.spacing(6),
    height: "80px",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  footerInner: {
    textAlign: "center"
  },
  footerLink: {
    marginRight: "1.125rem",
    fontWeight: "400",
    color: "#FFFFFF",
    "&:hover": {
      color: theme.palette.secondary.main,
      textDecoration: "none"
    }
  },
}));

const footers = {
  description: ['check_footer_company_about', 'check_footer_company_imprint', 'check_footer_legal_privacy'],
  link: ['ueber-denic/', 'ueber-denic/impressum/',  'ueber-denic/datenschutzerklaerung/' ]
}

export default function App() {
  return (
        <ThemeProvider theme={theme}>
          <AppInner/>
        </ThemeProvider>
  )
}

const LoadingBar = (props) => {
  const { t } = useTranslation();
  return (
      <>
        <Grid container justify="center">
          <CircularProgress size={100}/>
        </Grid>
        <br/>
      </>
  )
}

function AppInner(){
  const classes = useStyles();
  const { t } = useTranslation();

  const [loadedErrorCodeLinkMap, setLoadedErrorCodeLinkMap] = React.useState(false);
  const [error, setError] = React.useState(null);

  useEffect(() => {
    get_errorcode_docslink_map().then(result => {
      if (result.success) {
        Config.setErrorCodeLinkMap(result.data)
        setLoadedErrorCodeLinkMap(true)
      } else {
        setError(result.data)
      }
    })
  }, [])

  return (
      <>
        <CssBaseline />
        {/* Bar */}
        <div position="static" elevation={0} className={classes.appBar}>
          <Grid container className={classes.appBarInner}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10} className={classes.centerText}>
              <Link href="https://denic.de" className={classes.appBarItem}>
                <img src={process.env.PUBLIC_URL + '/images/logo_denic.svg'} alt="Denic eG" className={classes.denicLogo}/>
              </Link>
            </Grid>
            <Grid item xs={1} className={classes.rightText}>
              <LanguageSelect />
            </Grid>
          </Grid>
        </div>
        {/* Title */}
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
          <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
            NAST Predelegation Check
          </Typography>
          <Typography variant="h6" align="left" color="textSecondary" component="p" className={classes.introText}>
            {t("intro_txt")}
          </Typography>
        </Container>

        {/* Load Specific Page Content here via React-App-Router */}
        {
          (error !== null)
            ? <Container maxWidth="md" component="main" className={classes.heroContent}>
                <Alert severity={error.severity}>{t(error.message)}</Alert>
              </Container>
            : <>
              {
                loadedErrorCodeLinkMap === false
                  ? <Container maxWidth="md" component="main" className={classes.heroContent}>
                      <LoadingBar/>
                    </Container>
                  : <AppRouter/>
              }
            </>
        }

        <Container maxWidth="md" className={classes.linkContainer}>
          <Typography variant="h6" color="inherit" noWrap color="textPrimary">
            {t("check_header_toolbar_doc_link")}
          </Typography>
          <div className={classes.linkContainerInner}>
            <Link variant="subtitle2" className={classes.link}
                  href={i18n.language == "de" ? "https://www.denic.de/fileadmin/public/documentation/DENIC-23p.pdf"
                        : "https://www.denic.de/fileadmin/public/documentation/DENIC-23p_EN.pdf"}>
              {t("denic_p23_link_label")}
            </Link>
            <br/>
            <Link variant="subtitle2" className={classes.link}
                  href={Config.get_nast_docs_url()+"spec/spec_" + i18n.language + ".html#Predelegation-Check-Specification"}>
              {t("nast_spec_link_label")}
            </Link>
            <br/>
            <Link variant="subtitle2" className={classes.link}
                  href={Config.get_nast_docs_url()+"nast/api.html"}>
              {t("nast_api_link_label")}
            </Link>
            <br/>
            {
              loadedErrorCodeLinkMap === true &&
              <Link variant="subtitle2" className={classes.link}
                    href={Config.get_nast_docs_url()+"static/"+Config.getErrorCodeLinkMap()["nast-archive-name"]}>
                {t("nast_code_download_link_label") + " (Version " + Config.getErrorCodeLinkMap()["nast-archive-label"] + ")"}
              </Link>
            }
          </div>
        </Container>

        {/* Footer */}
        <div className={classes.footer}>
          <div className={classes.footerInner}>
              {footers.description.map((description, index) => (
                <Link href={"https://denic.de/"+footers.link[index]} className={classes.footerLink}>
                  {t(description)}
                </Link>
              ))}
          </div>
        </div>
        {/* End footer */}
      </>
  );
}
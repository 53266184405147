import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const fallbackLng = ["en"];
const availableLanguages = ["en", "de"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    //resources,
    fallbackLng: fallbackLng,

    debug: true,

    detection: {
      checkWhitelist: true
    },

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    },

    react: {
        useSuspense: false
      }

  });

export default i18n;

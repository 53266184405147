import React, { useState } from 'react';
import { useTranslation} from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Grid from "@material-ui/core/Grid";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Collapse from "@material-ui/core/Collapse";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {build_url} from "./Api"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme) => ({
    copyLinkInput: {
        overflow:"E",
    }
}));

function ShareLink (props){

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showShare, setshowShare] = useState(false);
    const { t } = useTranslation();
    const classes = useStyles()

    const url = build_url(window.location.origin, "/result/", props.domain, props.nameserver, props.dnskeys, props.debugMode)

    return (
        <div>
            <Button variant="contained" onClick={(event) => {
                setAnchorEl(event.currentTarget)
                setshowShare(!showShare)
            }}>
                {t("share_link_button_label")} <ExpandMoreIcon/>
            </Button>
            <Popover
                open={showShare}
                anchorEl={anchorEl}
                onClose={() => setshowShare(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box m={1}>
                    <FormControl variant="outlined">
                        <b>{t("share_link_label")}</b>
                        <InputBase
                            className={classes.copyLinkInput}
                            value={url.href}
                            readOnly
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            navigator.clipboard.writeText(url.href);
                                        }}
                                        edge="end"
                                    >
                                        <FileCopyOutlinedIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Box>
            </Popover>
        </div>
    )
}

export default function InputDisplayer(props){

    const [showInput, setShowInput] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <Grid container justify="space-between">
                <ShareLink domain={props.domain} nameserver={props.nameserver} dnskeys={props.dnskeys} debugMode={props.debugMode}/>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showInput}
                            onChange={(e) => {
                                setShowInput(!showInput)
                            }}
                            color="primary"
                        />
                    }
                    label={t('display_input_switch_label_text')}>
                </FormControlLabel>
            </Grid>
            <Collapse in={showInput} timeout="auto" unmountOnExit>
                <br/>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nameserver</TableCell>
                                <TableCell>IPs</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.nameserver.map((nameserver, i) => (
                                <TableRow key={"nameserver_table_row_"+i}>
                                    <TableCell>
                                        {nameserver.server}
                                    </TableCell>
                                    <TableCell>
                                        {nameserver.ips}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br/>
                <br/>
                { props.dnskeys.length > 0 &&
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>SEP, Flags Bit 15</TableCell>
                                <TableCell>Algorithmus</TableCell>
                                <TableCell>Dnskey</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.dnskeys.map((dnskey, i) => (
                                <TableRow key={"nameserver_table_row_"+i}>
                                    <TableCell>
                                        {dnskey.flags === "257" ? <CheckIcon/>: <ClearIcon/>}
                                    </TableCell>
                                    <TableCell>
                                        {dnskey.algorithm}
                                    </TableCell>
                                    <TableCell style={{wordBreak: "break-all"}}>
                                        {dnskey.dnskey}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                }

            </Collapse>
        </>
    )
}
import React, {useState} from 'react';
import Container from '@material-ui/core/Container';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {build_url} from "./Api";
import Alert from "@material-ui/lab/Alert";
import Link from "@material-ui/core/Link";
import Config from "./AppConfig";


const ParseV1Json = (props) => {

    const [jsonInput, setJsonInput] = useState("");
    const [jsonOutput, setJsonOutput] = useState("");
    const [error, setError] = useState(null);
    const [url, setURL] = useState("");

    const parse_and_format = () => {
        let obj = null
        try{
            obj = JSON.parse(jsonInput);
        } catch (error){
            setError(error.message)
            return
        }
        setJsonOutput(JSON.stringify(obj, null, 8))

        const domain = obj.params.domain
        const nameserver_raw = obj.params.nameserver
        const dnskeys_raw = obj.params.dnskey

        if (domain === undefined || nameserver_raw == undefined || dnskeys_raw === undefined){
            setError("Domain, nameserver or dnskeys not found in json")
        }

        const nameserver = []
        for (const [key, value] of Object.entries(nameserver_raw)) {
            nameserver.push({server: key, ips: value.join(" ")})
        }

        // console.log(dnskeys_raw)
        const dnskeys = dnskeys_raw.map(dnskey => {
            return {
                flags: dnskey.flags,
                algorithm: dnskey.algorithm,
                dnskey: typeof(dnskey.key) === typeof([]) ? dnskey.key.join(" ") : dnskey.key,
            }
        })
        // console.log(dnskeys)

        const url = build_url(window.location.origin, "/result/", domain, nameserver, dnskeys, true)
        setURL(url.href)


        setError(null)
    }

    return (
        <>
            <Container maxWidth="md" component="main">
                {error !== null &&
                    <>
                        <Alert onClose={
                            () => setError(null)
                        } severity={"error"}>
                            {error}
                        </Alert>
                        <br/>
                        <br/>
                    </>
                }
                <TextField
                    value={jsonInput}
                    onChange={event => setJsonInput(event.target.value)}
                    label="V1 JSON"
                    multiline
                    rows={5}
                    fullWidth
                    variant="outlined"
                />
                <br/>
                <br/>
                <Grid container item xs={12} justify="flex-end">
                    <Button variant="contained" color="primary" onClick={parse_and_format}>
                        Format
                    </Button>
                </Grid>
                <br/>
                <TextField
                    value={jsonOutput}
                    label="Formated JSON"
                    multiline
                    rows={jsonOutput.split(/\r\n|\r|\n/).length}
                    fullWidth
                    variant="outlined"
                />
                <br/>
                <br/>
                <Grid container item xs={12} justify="flex-end">
                    <Button variant="contained" color="primary" onClick={() => window.open(url,'_blank')}>
                        Run query
                    </Button>
                </Grid>

            </Container>

        </>
    )
}


export default ParseV1Json;
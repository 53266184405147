import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import clsx from  'clsx';


const useStyles = makeStyles((theme) => ({
    ipPaper: {
        padding: theme.spacing(0),
        margin: theme.spacing(1)
    },
    queryPaper: {
        padding: theme.spacing(2),
        margin: theme.spacing(1)
    },
    queryPaperTitleWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    ipPaperTitleWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(2),
    },
    expandButton: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandButtonOpen: {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    error: {
        backgroundColor: "rgba(217,78,31,.1)",
        borderRadius: 4
    }
}));

const NamerserverIPsTable = (props) => {
    return (
        <>
            {props.nameserver !== null &&
                <>
                    <Typography variant="h6" component="h2" >
                        <Trans>debug_determined_ips_headline</Trans>:
                    </Typography>
                    <br/>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nameserver</TableCell>
                                    <TableCell>IPs</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(props.nameserver).map((nameserver, i) => (
                                    <TableRow key={"debug_nameserver_table_row_"+i}>
                                        <TableCell>
                                            {nameserver}
                                        </TableCell>
                                        <TableCell>
                                            {props.nameserver[nameserver].join(' ')}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
        </>
    )
}

const QueryPaper = (props) => {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false)

    const DNSSection = (props) => {
        return (
            <>
                {
                    props.section.length > 0
                        && <>
                            <br/>
                            {props.label}:
                            <br/>
                            {props.section.map((rr, i) => (
                                <React.Fragment key={props.key_+"_"+i}>
                                    <span style={{wordBreak: "break-all"}}> {rr} </span>
                                    <br/>
                                </React.Fragment>
                            ))}

                        </>
                }
            </>
        )
    }

    const key_prefix = "debug_queries_output_"+props.nameserver+"_"+props.ip+"_"+props.rtype+"_"+props.protocol+"_section_"

    return (
        <Paper className={clsx(classes.queryPaper, props.response.result !== "success" && classes.error)}>
            {props.response.result === "success" ?
                <>
                    <div className={classes.queryPaperTitleWrapper}>
                        <Typography variant="h6" component="h2">
                            {props.rtype} ({props.protocol})
                        </Typography>
                        <IconButton
                            className={(expanded ? classes.expandButtonOpen : classes.expandButton)}
                            onClick={() => {setExpanded(!expanded)}}
                            aria-expanded={expanded}
                        >
                            <ExpandMoreIcon />
                        </IconButton>

                    </div>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Typography variant="body1">
                            id {props.response.id} <br/>
                            rcode {props.response.rcode} <br/>
                            flags {props.response.flags} <br/>
                            <DNSSection label="QUESTION" section={props.response.question} key_={key_prefix+"q"}/>
                            <DNSSection label="ANSWER" section={props.response.answer} key_={key_prefix+"a"}/>
                            <DNSSection label="AUTHORITY" section={props.response.authority} key_={key_prefix+"au"}/>
                            <DNSSection label="ADDITIONAL" section={props.response.additional} key_={key_prefix+"add"}/>
                        </Typography>
                    </Collapse>
                </>
                : <>
                    <div className={classes.queryPaperTitleWrapper}>
                        <Typography variant="h6">
                            {props.rtype} ({props.protocol})
                        </Typography>
                        <Typography variant="body1">
                            Nast Api Error ({props.response.error}): {props.response.message}
                        </Typography>
                    </div>
                </>
            }
        </Paper>
    )
}

const IPPaper = (props) => {

    const classes = useStyles();

    const [expanded, setExpanded] = useState(false)


    let containsErrors = false
    containsErrorsLoop:
    for( const [rtype, protocols] of Object.entries(props.rtypes)){
        for( const [protocol, query] of Object.entries(protocols)){
            if(query.result !== "success"){
                containsErrors = true
                break containsErrorsLoop
            }
        }
    }

    return (
        <Paper className={classes.ipPaper}>

            <div className={clsx(classes.ipPaperTitleWrapper, containsErrors && classes.error)}>
                <Typography variant="h6" component="h2" >
                    {props.nameserver} {props.ip}
                </Typography>
                <IconButton
                    className={(expanded ? classes.expandButtonOpen : classes.expandButton)}
                    onClick={() => {setExpanded(!expanded)}}
                    aria-expanded={expanded}
                >
                    <ExpandMoreIcon />
                </IconButton>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {
                    Object.keys(props.rtypes).map(rtype => (
                        <React.Fragment key={"debug_queries_output_"+props.nameserver+"_"+props.ip+"_"+rtype}>

                            {Object.keys(props.rtypes[rtype]).map(protocol => (
                                <React.Fragment   key={"debug_queries_output_"+props.nameserver+"_"+props.ip+"_"+rtype+"_"+protocol}>
                                    <QueryPaper nameserver={props.nameserver} ip={props.ip} rtype={rtype} protocol={protocol}
                                                response={props.rtypes[rtype][protocol]}
                                    />
                                </React.Fragment>
                            ))}

                        </React.Fragment>
                    ))
                }
            </Collapse>
        </Paper>
    )
}

export default function DebugDisplayer(props){

    return (
        <>
            <br/>
            <br/>
            <Typography variant="h4" component="h2" >
                <Trans>debug_output_headline</Trans>:
            </Typography>
            <br/>
            <NamerserverIPsTable nameserver={props.debug.nameserver}/>
            <br/>
            {Object.keys(props.debug.queries).length > 0
                && <>
                    <Typography variant="h6" component="h2" >
                        <Trans>debug_query_results_headline</Trans>:
                    </Typography>
                    <br/>
                    {Object.keys(props.debug.queries).map(nameserver => (
                        <React.Fragment key={"debug_queries_output_"+nameserver}>
                            {
                                Object.keys(props.debug.queries[nameserver]).map(ip => (
                                    <IPPaper  key={"debug_queries_output_"+nameserver+"_"+ip}
                                              nameserver={nameserver} ip={ip}
                                              rtypes={props.debug.queries[nameserver][ip]}
                                    />
                                ))
                            }
                        </React.Fragment>
                    ))}
                </>
            }

        </>
    )
}
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslation} from "react-i18next";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import {call_check, get_errorcode_docslink_map} from "./Api";
import Config from './AppConfig';
import DebugDisplayer from './DebugDisplayer.js'
import {ResultDisplayer, IssueDisplayerResponsive} from './ResultDisplayer.js'
import InputDisplayer from './InputDisplayer.js'
import i18n from "i18next";
import {useParams} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  successPaper: {
    padding: theme.spacing(3),
    backgroundColor: "#2ba182",
  },
  failPaper: {
    padding: theme.spacing(3),
    backgroundColor: "#f44336;",
  }
}));

export default function Result(props) {

  const {domain_from_url} = useParams()

  const initConsts = () => {

    if(domain_from_url !== undefined){
      const urlParams = new URLSearchParams(window.location.search);

      const nameserver = []
      const dnskeys = []
      let debugMode = false

      for(var [key, value] of urlParams.entries()) {
        if(key === "ns1" || key === "ns2" || key === "nsX"){
          const ns = value.split(",")
          nameserver.push({server: ns[0], ips: ns.slice(1).join(",")})
        }
        if(key === "dnskey"){
          const dnskey = value.split(" ")
          dnskeys.push({flags: dnskey[0], algorithm: dnskey[2], dnskey: dnskey[3]})
        }
        if(key === "debug"){
          debugMode = value
        }
      }

      return {
        domain: domain_from_url,
        nameserver,
        dnskeys,
        debugMode
      }
    }else{
      return (props.location && props.location.state)
          ? props.location.state
          : {domain: '', nameserver: [], dnskeys: [], debugMode: false}
    }
  }

  const {domain, nameserver, dnskeys, debugMode} = initConsts()

  const getInitState = () => {
    let error = null
    if(domain === "") {
      error = {severity: 'error', message: 'no_domain_result_page_error'}
    }

    return {
      result: null,
      error
    }
  }

  const [state, setState] = useState(getInitState());

  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if(nameserver.length < 2){
      setState(prevState => ({
        ...prevState,
        error: {severity: 'error', message: 'not_enough_nameserver_error_text'}
      }))
    }else{
      call_check(domain, nameserver, dnskeys, debugMode)
          .then(result => {
            if(result.success){
              setState(prevState => ({
                ...prevState,
                result: result.data,
                error: null,
              }))
            } else{
              setState(prevState => ({
                ...prevState,
                error: result.data,
              }))
            }
          })
    }
  }, [])

  const back_to_form = (event) => {
    event.preventDefault();
    props.history.push({
      pathname: '/',
      state: {domain, nameserver, dnskeys, debugMode}
    });
  };

  const LoadingBar = (props) => {
    return (
        <>
          <Grid container justify="center">
            <CircularProgress size={100}/>
          </Grid>
          <br/>
          <Grid container justify="center">
            <Typography variant="h4" color="textPrimary">
              {t("loading_result_data_message")}
            </Typography>
          </Grid>
        </>
    )
  }

  return (
    <>
      {/* Check result  */}
      <Container maxWidth="md" component="main" className={classes.heroContent}>

        {state.error !== null &&
          <>
            <Grid item xs={12}>
              <Alert severity={state.error.severity}>
                {t(state.error.message)}
              </Alert>
            </Grid>
            <br/>
            <InputDisplayer domain={domain} nameserver={nameserver} dnskeys={dnskeys} debugMode={debugMode}/>
          </>
        }
        {state.error === null &&
          <>
            {(state.result === null) &&  <LoadingBar/>}
            {(state.result !== null) &&
              <>
                <ResultDisplayer domain={domain} result={state.result}/>
                <br/>
                <InputDisplayer domain={domain} nameserver={nameserver} dnskeys={dnskeys} debugMode={debugMode}/>
                <IssueDisplayerResponsive docs_base_url={Config.get_nast_docs_url() + "spec/spec_" + i18n.language + ".html#"}
                                          errorcode_docslink_map={Config.getErrorCodeLinkMap()} domain={domain}
                                          result={state.result}/>
              </>
            }
          </>
        }
        <br/>
        <br/>
        <Grid container justify="flex-end">
          <Button variant="contained" color="secondary" onClick={back_to_form}>
            {t("back_btn")}
          </Button>
        </Grid>
        {(state.error === null && state.result !== null && debugMode && state.result.debug.nameserver !== null) && <DebugDisplayer debug={state.result.debug}/>}
      </Container>
    </>
  );
}
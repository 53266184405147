import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Form from './Form';
import Result from './Result';
import ParseV1Json from './ParseV1Json'

const AppRouter = (props) => (
  <BrowserRouter>
      <Switch>
        <Route component={Form} exact path="/" />
        <Route component={ParseV1Json} exact path="/parse_v1_json" />
        <Route component={Result} path="/result/:domain_from_url" />
        <Route component={Result} path="/result" />
        <Redirect from="/*" to="/" />
      </Switch>
  </BrowserRouter>
);

export default AppRouter;
import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const languageMap = {
  en: { label: "EN", nextKey: "de", dir: "ltr", active: true },
  de: { label: "DE", nextKey: "en", dir: "ltr", active: false }
};

const useStyles = makeStyles((theme) => ({
  languageChangeButton: {
    cursor: "pointer",
    fontWeight: 700,
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
      textDecoration: "none"
    }
  },
}));

const LanguageSelect = () => {

  const classes = useStyles();

  const selected = localStorage.getItem("i18nextLng") || "en";
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);

  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  return (
      <Typography className={classes.languageChangeButton} onClick={() => i18next.changeLanguage(languageMap[selected].nextKey)}>
        {languageMap[languageMap[selected].nextKey].label}
      </Typography>
  );
};

export default LanguageSelect;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Trans } from "react-i18next";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';
import clsx from  'clsx';

const useStyles = makeStyles((theme) => ({
    successPaper: {
        padding: theme.spacing(3),
        backgroundColor: "#2ba182",
    },
    failPaper: {
        padding: theme.spacing(3),
        backgroundColor: "rgba(217,78,31,.1)",
        color: "#d94e1f"
    },
    topLeftTextForTableCell: {
        verticalAlign: "top"
    },
    warning: {
        backgroundColor: "rgba(255, 145, 0,.3)",
    },
    warningHover: {
        '&:hover': {
            backgroundColor: "rgba(255, 145, 0,.4)",
        },
    },
    error: {
        backgroundColor: "rgba(217,78,31,.1)",
    },
    errorHover: { 
        '&:hover': {
            backgroundColor: "rgba(217,78,31,.2)",
        },
    },
    hoverPointerCursor: {
        '&:hover': {
            cursor: "pointer",
        },
    },
    expandButton: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandButtonOpen: {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    padding2: {
        padding: theme.spacing(2)
    },
    link: {
        color: "#007A9D",
        "&:hover": {
          color: theme.palette.secondary.main,
          textDecoration: "none"
        }
    }
}));


export function ResultDisplayer(props) {

    const classes = useStyles();

    return (
        <Paper className={props.result.success ? classes.successPaper : classes.failPaper}>
            <Typography variant="h4" component="h2" align="center" >
                {props.result.success ?  <Trans>check_success_message</Trans>:  <Trans>check_failure_message</Trans>}
            </Typography>
            <br/>
            <Typography align="center">
                <Trans>checked_domain_text</Trans>: {props.domain}
            </Typography>
        </Paper>
    )
}

function ParameterTable(props){
    return(
        <>
            Parameter:
            <table>
                <tbody>
                {props.issue.arguments !== null &&
                    <>
                        {
                            Object.keys(props.issue.arguments).map((argumentkey, j) => (
                                <tr key={"issue_param_"+props.i+"_"+j}>
                                    <td style={{verticalAlign: "top"}}>
                                        {argumentkey}:
                                    </td>
                                    <td style={{wordBreak: "break-all", verticalAlign: "top"}}>
                                        {props.issue.arguments[argumentkey]}
                                    </td>
                                </tr>
                            ))
                        }
                    </>
                }
                </tbody>
            </table>
        </>
    )
}

function IssueRowMobile(props){

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow className={props.issue.severity === "warning" ? classes.warning : classes.error}>
                <TableCell style={{borderBottom: 'unset'}} className={classes.topLeftTextForTableCell}>{props.issue.severity.charAt(0).toUpperCase() + props.issue.severity.slice(1)}</TableCell>
                <TableCell style={{borderBottom: 'unset'}} className={classes.topLeftTextForTableCell}>
                    <Link target="_blank" href={props.docs_base_url + props.errorcode_docslink_map[props.issue.code]}>{props.issue.code}</Link>
                </TableCell>
                <TableCell style={{borderBottom: 'unset'}} className={classes.topLeftTextForTableCell}>{props.issue.message}</TableCell>
                <TableCell style={{borderBottom: 'unset'}} className={classes.topLeftTextForTableCell}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}
                                className={(open ? classes.expandButtonOpen : classes.expandButton)}>
                        <ExpandMoreIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow className={props.issue.severity === "warning" ? classes.warning : classes.error}>
                <TableCell style={{ padding: 0}} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div className={classes.padding2}>
                            <ParameterTable  issue={props.issue} i={props.i}/>
                        </div>
                    </Collapse>

                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export function IssueDisplayerMobile(props) {

    return (
        <>
            {'issues' in props.result &&
            <>
                <br/>
                <br/>
                <Typography variant="h4" component="h2" align="left">
                        <Trans>issues_text</Trans>:
                </Typography>
                <br/>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Severity</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Message</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {"issues" in props.result && props.result.issues.map((issue, i) => (
                                <IssueRowMobile key={"issue_table_row_"+i} issue={issue} i={i}
                                                docs_base_url={props.docs_base_url}
                                                errorcode_docslink_map={props.errorcode_docslink_map}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
            }
        </>
    )
}

export function IssueDisplayer(props) {

    const classes = useStyles();

    return (
        <>
            {'issues' in props.result &&
            <>
                <br/>
                <br/>
                <Typography variant="h4" component="h2" align="left">
                    <Trans>issues_text</Trans>:
                </Typography>
                <br/>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Severity</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {"issues" in props.result && props.result.issues.map((issue, i) => (
                                <TableRow 
                                    key={"issue_table_row_"+i} 
                                    className={issue.severity === "warning" ? classes.warning : classes.error}
                                >
                                    <TableCell className={classes.topLeftTextForTableCell}>{issue.severity.charAt(0).toUpperCase() + issue.severity.slice(1)}</TableCell>
                                    <TableCell className={classes.topLeftTextForTableCell}>
                                        <Link className={classes.link} target="_blank" href={props.docs_base_url + props.errorcode_docslink_map[issue.code]}>{issue.code}</Link>   
                                    </TableCell>
                                    <TableCell className={classes.topLeftTextForTableCell}>
                                        {issue.message}
                                        <br/>
                                        <br/>
                                        <ParameterTable issue={issue} i={i}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
            }
        </>
    )
}

export const IssueDisplayerResponsive = (props) => {
    return (
        <>
            <Hidden xsDown>
                <IssueDisplayer docs_base_url={props.docs_base_url}
                                          errorcode_docslink_map={props.errorcode_docslink_map} domain={props.domain}
                                          result={props.result}/>
            </Hidden>
            <Hidden smUp>
                <IssueDisplayerMobile docs_base_url={props.docs_base_url}
                                      errorcode_docslink_map={props.errorcode_docslink_map} domain={props.domain}
                                      result={props.result}/>
            </Hidden>
        </>
    )
}
import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useTranslation} from "react-i18next";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NativeSelect from '@material-ui/core/NativeSelect';

export const DnskeyInput = (props) => {
    const { t } = useTranslation();
    return (

        <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="body1" align="left" color="textPrimary">
                    DNSKey
                </Typography>
            </Grid>

            {props.dnskeys.map((dnskey, id) => (
                <React.Fragment key={"dnskey_input_"+id}>
                    <Grid item xs={12} md={2} required>
                        SEP, Flags Bit 15:
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <RadioGroup row name="flags" value={dnskey.flags} onChange={e => props.handleMapInputChange(e, 'dnskeys', id)}>
                            <FormControlLabel disabled={props.fetchingApi} value="257" control={<Radio />} label="gesetzt (KSK/CSK)" />
                            <FormControlLabel disabled={props.fetchingApi} value="256" control={<Radio />} label="nicht gesetzt (ZSK)" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        Algorithmus:
                    </Grid>

                    <Grid item xs={12} md={10}>
                        <NativeSelect disabled={props.fetchingApi} fullWidth name="algorithm" value={dnskey.algorithm}
                                      onChange={e => props.handleMapInputChange(e, 'dnskeys', id)}>
                            {
                                [
                                    [3, 'DSA/SHA1 - 3'], [5, 'RSA/SHA1 - 5'], [6, 'DSA/NSEC3/SHA1 - 6'], [7, 'RSA/NSEC3/SHA1 - 7'],
                                    [8, 'RSA/SHA256 - 8'], [10, 'RSA/SHA512 - 10'], [12, 'ECC/GOST - 12'], [13, 'ECDSA/P256/SHA256 - 13'],
                                    [14, 'ECDSA/P384/SHA384 - 14'], [15, 'ED25519 - 15', true], [16, 'ED448 - 16', true]
                                ].map(o => <option key={"dnskey_input_"+id+"_option_"+o[0]} disabled={o[2]} value={o[0]}>{o[1]}</option>)
                            }
                        </NativeSelect>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        Dnskey:
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <TextField error={dnskey.invalidKey !== undefined}
                                   helperText={dnskey.invalidKey === undefined ? "": t("invalid_dnskey_label")}
                                   multiline fullWidth name="dnskey" value={dnskey.dnskey} variant="outlined" required
                                   disabled={props.fetchingApi} onChange={e => props.handleMapInputChange(e, 'dnskeys', id)}
                        />
                    </Grid>

                </React.Fragment>
            ))}

            {props.dnskeys.length < 5 &&
            <Grid container item xs={12} justify="flex-end">
                <Button color="default" onClick={e => props.handleAddResourceClick(e, 'dnskeys')} disabled={props.fetchingApi}>
                    {t("form_btn_add")}
                </Button>
            </Grid>
            }
        </Grid>
    );
}


export const NameserverInput = (props) => {
    const { t } = useTranslation();
    return (
        <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="body1" align="left" color="textPrimary" gutterBottom>
                    Nameserver:
                </Typography>
            </Grid>

            {props.nameserver.map((ns, i) => (
                <React.Fragment key={"nameserver_input_"+i}>
                    <Grid item xs={12} md={2} required>
                        Nameserver {i+1}:
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField error={ns.invalidHostname !== undefined}
                                   helperText={ns.invalidHostname === undefined ? "": t("invalid_hostname_label")}
                                   fullWidth multiline disabled={props.fetchingApi}
                                   id={'ns_' + i} label={'Hostname'} name='server' value={ns.server}
                                   variant="outlined" onChange={e => props.handleMapInputChange(e, 'nameserver', i)} required={i < 2}/>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField error={ns.invalidIps !== undefined}
                                   helperText={ns.invalidIps === undefined ? "": t("invalid_ips_label") + ": " + ns.invalidIps}
                                   fullWidth multiline
                                   disabled={props.fetchingApi} id={'ns_ip_' + i} label={t("form_input_ips_helper")}
                                   name='ips' variant="outlined"
                                   value={ns.ips} onChange={e => props.handleMapInputChange(e, 'nameserver', i)}/>
                    </Grid>
                </React.Fragment>
            ))}

            <Grid container item xs={12} justify="flex-end" style={{flexWrap: "wrap-reverse"}}>
                <Button color="default" onClick={e => props.handleAddResourceClick(e, 'nameserver')} disabled={props.fetchingApi}>
                    {t("form_btn_add")}
                </Button>
            </Grid>

        </Grid>
    );
}

export const DomainInput = (props) => {
    const { t } = useTranslation();
    return (
        <Grid container item xs={12} spacing={3} alignItems="center">
            <Grid item xs={12} md={9}>
                <TextField
                    error={props.invalidDomain !== undefined}
                    helperText={props.invalidDomain === undefined ? "": t("invalid_domain_label")}
                    disabled={props.fetchingApi} fullWidth id="domain" label="Domain" name="domain" variant="outlined"
                    value={props.domain} onChange={props.handleDomainInputChange} required/>
            </Grid>
            <Grid item xs={12} md={3}>
                <Button fullWidth variant="contained" color="secondary" onClick={props.handleOnSubmit}
                        disabled={props.fetchingApi}>
                    {t("execute_btn")}
                </Button>
            </Grid>
        </Grid>
    )
}
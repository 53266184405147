import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useTranslation} from "react-i18next";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import {call_resolve, getIPListOfString} from "./Api";
import Switch from "@material-ui/core/Switch";
import Backdrop from "@material-ui/core/Backdrop";
import {validateNameserver, validateHostname, validateDnskeys, validateIPs, validateDnskey} from "./ValidationFunctions"
import {DomainInput, DnskeyInput, NameserverInput} from "./FormInputs"


const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    loadingButtonWrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const Form = (props) => {

    const { domain, nameserver, dnskeys, debugMode } = (props.location && props.location.state) || {};

    const [state, setState] = useState({
        fetchingApi: false,
        domain: domain !== undefined ? domain : '',
        nameserver: nameserver !== undefined ? nameserver : [ {server: '', ips: ''}, {server: '', ips: ''} ],
        dnskeys: dnskeys !== undefined ? dnskeys : [],
        message: null,
        debugMode: debugMode !== undefined ? debugMode : false,
    });

    const go_result_page = (domain, nameserver, dnskeys) => {
        let validationSuccess = true

        const domainValidationSuccess = validateHostname(domain)
        if(!domainValidationSuccess) {
            validationSuccess = false
            setState(prevState => ({
                ...prevState,
                domain,
                invalidDomain: true
            }))
        }

        const {nameserverValidationSuccess, newNameserver} = validateNameserver(nameserver)
        if(!nameserverValidationSuccess) {
            validationSuccess = false
            setState(prevState => ({
                ...prevState,
                nameserver: newNameserver
            }))
        }

        const {dnskeysValidationSuccess, newDnskeys} = validateDnskeys(dnskeys)
        if(!dnskeysValidationSuccess) {
            validationSuccess = false
            setState(prevState => ({
                ...prevState,
                dnskeys: newDnskeys
            }))
        }

        if(validationSuccess){
            props.history.push({
                pathname: '/result',
                state: {
                    domain,
                    nameserver,
                    dnskeys,
                    debugMode: state.debugMode
                }
            });
        }
    }

    const fill_form_and_go_result_page = (domain) => {
        setState(prevState => ({
            ...prevState,
            fetchingApi: true,
            message: null,
            domain
        }))
        call_resolve(domain)
            .then(result => {
                if(result.success){
                    const newState = {
                        domain: result.data.domain,
                        nameserver: result.data.nameserver,
                        dnskeys: result.data.dnskeys,
                        debugMode: state.debugMode
                    }
                    props.history.push({
                        pathname: '/result',
                        state: newState
                    });
                } else{
                    setState(prevState => ({
                        ...prevState,
                        message: result.data,
                        fetchingApi: false
                    }))
                }
            })
    }

    const handleOnSubmit = (event) => {
        event.preventDefault();

        const newDomain = state.domain.replace(/^\s+|^\n+|\s+$|\n+$/g, '')
        if(newDomain === ""){
            setState(prevState => ({
                ...prevState,
                message: {severity: 'error', message: 'empty_domain_input_error_text'}
            }))
            return
        }

        const newNameserver = []
        state.nameserver.forEach(ns => {
            const server = ns.server.replace(/^\s+|^\n+|\s+$|\n+$/g, '')
            const ips = getIPListOfString(ns.ips.replace(/^\s+|^\n+|\s+$|\n+$/g, '')).join(" ")
            if(server !== "" || ips !== ""){
                newNameserver.push({
                    ...ns,
                    server,
                    ips
                })
            }
        })

        const newDnskeys = []
        state.dnskeys.forEach(dnskey =>{
            const key = dnskey.dnskey.replace(/^\s+|^\n+|\s+$|\n+$/g, '')
            if(key !== ""){
                newDnskeys.push({
                    ...dnskey,
                    dnskey: key
                })
            }
        })

        if(newNameserver.length >= 2){
            go_result_page(newDomain, newNameserver, newDnskeys)
        }else if(newNameserver.length === 0 && newDnskeys.length === 0){
            fill_form_and_go_result_page(newDomain)
        } else {
            setState(prevState => ({
                ...prevState,
                message: {severity: 'error', message: 'not_enough_nameserver_error_text'}
            }))
        }
    };

    const handleDomainInputChange = (event) => {
        const { name, value } = event.target;
        if(state.invalidDomain){
            const validationSuccess = validateHostname(value)
            if(validationSuccess){
                setState((prevState) => ({
                    ...prevState,
                    invalidDomain: undefined
                }));
            }
        }
        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    const handleDebugSwitchInputChange = (event) => {
        event.persist()
        setState(prevState => ({
            ...prevState,
            debugMode: event.target.checked
        }))
    };

    const handleMapInputChange = (event, key, index) => {

        const { name, value } = event.target;
        const map = state[key];

        if(key === "nameserver" && name === "server"){
            if(map[index]["invalidHostname"]){
                const validationSuccess = validateHostname(value)
                if(validationSuccess){
                    delete map[index]["invalidHostname"]
                }
            }
        }else if(key === "nameserver" && name === "ips"){
            if(map[index]["invalidIps"] !== undefined){
                const invalidIps = validateIPs(value)
                if(invalidIps.length == 0){
                    delete map[index]["invalidIps"]
                }else {
                    map[index]["invalidIps"] = invalidIps
                }
            }
        }else if(key === "dnskeys" && name === "dnskey"){
            if(map[index]["invalidKey"]){
                const validationSuccess = validateDnskey(value)
                if(validationSuccess){
                    delete map[index]["invalidKey"]
                }
            }
        }

        map[index][name] = value;
        setState((prevState) => ({
            ...prevState,
            key: map
        }));
    };

    const handleAddResourceClick = (event, key) => {
        let resource = state[key]

        if (key === 'nameserver') {
            resource.push({server: '', ips: ''});
        } else {
            resource.push({flags: "257", algorithm: 5, dnskey: ''});
        }

        setState((prevState) => ({
            ...prevState,
            key: resource
        }));
    };

    const fillForm = (event) => {
        setState(prevState => ({
            ...prevState,
            fetchingApi: true,
            message: null
        }))
        call_resolve(state.domain)
            .then(result => {
                if(result.success){
                    setState(prevState => ({
                        ...prevState,
                        domain: result.data.domain,
                        nameserver: result.data.nameserver,
                        dnskeys: result.data.dnskeys,
                        message: null,
                        fetchingApi: false
                    }))
                } else{
                    setState(prevState => ({
                        ...prevState,
                        message: result.data,
                        fetchingApi: false
                    }))
                }
            })
    };

    const clearForm = (event) => {
        setState(prevState => ({
            ...prevState,
            domain: '',
            nameserver: [ {server: '', ips: ''}, {server: '', ips: ''} ],
            dnskeys: [],
            message: null,
        }))
    };

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            {/* Form  */}
            <Container maxWidth="md" component="main" className={classes.root}>
                <form className={classes.root} noValidate autoComplete="off" onSubmit={handleOnSubmit}>
                    <Grid container spacing={1}>
                        <DomainInput domain={state.domain} invalidDomain={state.invalidDomain}
                                     fetchingApi={state.fetchingApi} handleDomainInputChange={handleDomainInputChange}
                                     handleOnSubmit={handleOnSubmit}
                        />
                        {state.message !== null &&
                        <Grid item xs={12}>
                            <Alert onClose={
                                () => {
                                    setState(prevState => ({
                                        ...prevState,
                                        message: null
                                    }))
                                }
                            } severity={state.message.severity}>{t(state.message.message)}
                            </Alert>
                        </Grid>
                        }
                        <Grid item xs={12} ></Grid>
                        <Grid item xs={12} ></Grid>
                        <Grid item xs={12} ></Grid>
                        <Grid container item xs={12} spacing={3} justify="flex-end">
                            <Grid item>
                                <Button color="default" onClick={e => clearForm(e)} disabled={state.fetchingApi}>
                                    {t("form_btn_clear_form")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button color="default" onClick={e => fillForm(e)} disabled={state.fetchingApi}>
                                    {t("form_btn_fill_form")}
                                </Button>
                            </Grid>

                        </Grid>

                        {/* Spacers to be improved with makestyles here */}
                        <Grid item xs={12}><Divider/></Grid>

                        <NameserverInput nameserver={state.nameserver} handleMapInputChange={handleMapInputChange}
                                         fetchingApi={state.fetchingApi} debugMode={state.debugMode}
                                         fillForm={fillForm} clearForm={clearForm}
                                         handleAddResourceClick={handleAddResourceClick}
                                         handleDebugSwitchInputChange={handleDebugSwitchInputChange}
                        />


                        {/* Spacers to be improved with makestyles here */}
                        <Grid item xs={12} ></Grid>
                        <Grid item xs={12} ></Grid>
                        <Grid item xs={12} ></Grid>
                        <Grid item xs={12}><Divider/></Grid>

                        <DnskeyInput dnskeys={state.dnskeys} handleMapInputChange={handleMapInputChange}
                                     fetchingApi={state.fetchingApi}
                                     handleAddResourceClick={handleAddResourceClick}
                                     handleDebugSwitchInputChange={handleDebugSwitchInputChange}
                        />
                        <Grid item xs={12} ></Grid>
                        <Grid item xs={12} ></Grid>
                        <Grid item xs={12}><Divider/></Grid>
                        <Grid item xs={12} ></Grid>

                        <Grid container item xs={12} spacing={2} justify="flex-end"
                              style={{flexWrap: "wrap-reverse", justifyContent: "space-between"}}
                        >
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={state.debugMode}
                                            disabled={state.fetchingApi}
                                            onChange={handleDebugSwitchInputChange}
                                            color="primary"
                                        />
                                    }
                                    label={t('debug_mode_switch_label')}>
                                </FormControlLabel>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="secondary" onClick={handleOnSubmit}
                                        disabled={state.fetchingApi}>
                                    {t("execute_btn")}
                                </Button>
                            </Grid>
                        </Grid>

                        <Backdrop open={state.fetchingApi}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Grid>

                </form>

            </Container>
        </>
    );
}

export default Form;
import {getIPListOfString} from "./Api.js"
import { Address4, Address6} from "ip-address"

export function validateHostname(domain){
    const re = /^(([^\.]{1,63}\.)+([^\.]{1,63})\.?)$/;
    return re.test(domain)
}

export function validateDnskey(dnskey){
    const re = /^[a-zA-Z0-9+/ ]+={0,2}$/;
    return re.test(dnskey)
}

export function validateIP(ip){
    if(ip.includes(".")){
        try{
            const ipObject = new Address4(ip)
        } catch (error){
            return false
        }
    } else if (ip.includes(":")) {
        try {
            const ipObject = new Address6(ip)
        } catch (error) {
            return false
        }
    }
    return true
}

export function validateIPs(ipString){
    const ips = getIPListOfString(ipString)
    let invalidIps = []

    ips.forEach(ip => {
        if(!validateIP(ip)){
            invalidIps.push(ip)
        }
    })
    return invalidIps
}

export function validateNameserver(nameserver){
    let validationSuccess = true

    const newNameserver = nameserver.map(ns =>{

        const invalidIps = validateIPs(ns["ips"])
        if(invalidIps.length > 0){
            validationSuccess = false
        }

        const isHostnameValid = validateHostname(ns.server)

        let newNS = {
            'server': ns.server,
            'ips': ns.ips,
        }
        if(invalidIps.length > 0){
            newNS['invalidIps'] = invalidIps.join(", ")
        }
        if(isHostnameValid === false){
            newNS['invalidHostname'] = true
            validationSuccess = false
        }

        return newNS
    })

    return {nameserverValidationSuccess: validationSuccess, newNameserver}
}

export function validateDnskeys(dnskeys){

    let validationSuccess = true

    const newDnskeys = dnskeys.map(dnskey =>{

        const isDnskeyValid = validateDnskey(dnskey.dnskey)

        let newDnskey = {...dnskey}
        if(!isDnskeyValid){
            newDnskey.invalidKey = true
            validationSuccess = false
        }
        return newDnskey
    })

    return {dnskeysValidationSuccess: validationSuccess, newDnskeys}
}
